import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function ScrollTriggerComponent(props) {
    const ref = useRef(null);
    const { scrub } = props

    useEffect(() => {
        const element = ref.current;

        gsap.fromTo(
            element,
            {
                opacity: 0,
                y: 100,
            },
            {
                opacity: 1,
                y: 0,
                duration: 0.8,
                scrollTrigger: {
                    trigger: element,
                    // toggleActions: "restart none none none",
                    // markers: 'true',
                    start: 'top 70%',
                    end: 'top 400px',
                    scrub: scrub
                },
            }
        );
    }, []);

    return <div ref={ref}>{props.children}</div>;
}

export default ScrollTriggerComponent;
