import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'
import gsap from 'gsap'
import { Helmet } from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHtml5, faCss3Alt, faJs, faPython, faNodeJs, faReact, faGithub } from '@fortawesome/free-brands-svg-icons'

import { faCode, faSpider, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import {
  faLeaf as faLeafSolid, // Import the solid version of the leaf icon from free-solid-svg-icons
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDatabase, faServer, faCamera, faMusic } from '@fortawesome/free-solid-svg-icons'

import FadeIn from '../FadeIn'

library.add(
  faLeafSolid,
  {
    prefix: 'fal',
    iconName: 'hindi',
    icon: [
      500, // Width
      500, // Height
      [], // Path data
      null, // Ligatures
      'M375.31,237.75c0,8.1-2.7,16.2-2.7,24.3,0,67.5-59.5,131.1-142.9,131.1s-142.9-63.6-142.9-131.1c0-8.1-2.7-16.2-2.7-24.3,0-56.7,46.9-104.9,113.8-121.7v-32.4c0-18.9,10.8-35.1,26.4-43.1-15.6-8.1-26.4-24.3-26.4-43.1V59.5h37.8v33.3c0,18.9,10.8,35.1,26.4,43.1-15.6,8.1-26.4,24.3-26.4,43.1v32.4c66.9,16.8,113.8,64.9,113.8,121.7zm-198.8,0c0,41.4,36,75,80.8,75s80.8-33.6,80.8-75c0-41.4-36-75-80.8-75s-80.8,33.6-80.8,75z', // Path data for the Hindi script letter "ह"
    ],
  },
  {
    prefix: 'fal',
    iconName: 'english',
    icon: [
      500, // Width
      500, // Height
      [], // Path data
      null, // Ligatures
      'M65,10l-60,0 0,80 60,0M5,50l50,0',
    ],
  }
)

function Pop({ children, vars }) {
  const el = useRef()
  const animation = useRef()

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      animation.current = gsap.from(el.current.children, {
        scale: 0,
        ...vars,
      })
    })
    return () => ctx.revert()
  }, [])

  return (
    <span ref={el} className="sm:w-1/5 lg:w-1/4 mx-auto lg:mx-0">
      {children}
    </span>
  )
}

const SkillCard = ({
  title,
  icon,
  color,
  projectsList,
  projectarray = Array.from({ length: 11 }, (_, i) => i),
  isDarkMode,
}) => {
  const buttonRef = useRef(null)

  const handleClick = () => {
    console.log('mouse enter')
    gsap.to(buttonRef.current, {
      scale: 1.2,
      duration: 0.2,
      ease: 'power1.out',
    })
  }

  const getOut = () => {
    gsap.to(buttonRef.current, {
      scale: 1,
      duration: 0.2,
      ease: 'power1.out',
    })
  }

  return (
    <div
      className={`select-none rounded-lg mx-auto h-40 p-4 w-48 sm:w-44 lg:w-48 md:w-48  xl:w-48 2xl:w-56 m-4 flex flex-col items-center justify-center cursor-pointer hover:scale-101 ${
        isDarkMode
          ? 'bg-transparent border border-gray-600 hover:bg-gray-900 skillsBox shadow-xl'
          : 'bg-gray-100 skillsBox'
      }`}
      ref={buttonRef}
      onMouseEnter={handleClick}
      onMouseLeave={getOut}
      onClick={() => projectsList(projectarray)}>
      <FontAwesomeIcon icon={icon} size="3x" style={{ color: isDarkMode && color === '#333333' ? 'white' : color }} />
      <h3
        className={`text-sm px-5 py-2 rounded-lg font-semibold mt-4 shadow-xl lg:shadow-lg md:shadow-lg shadow-gray-400 dark:shadow-lg dark:shadow-gray-900 transition ease-in-out duration-300 ${
          isDarkMode ? 'text-white border' : 'border border-black text-gray-800'
        }`}>
        {title}
        <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
      </h3>
    </div>
  )
}

const Skills = ({ isDarkMode, handleBackToTop }) => {
  const [title, setTitle] = useState('Skills - Jatin Saini')
  const [frgment, setFrgment] = useState([])
  const notices = [
    {
      id: 1,
      title: 'Senscart',
      message:
        'The Senescart project is a progressive web app built with Python (Flask) that fetches and sorts data from different websites to help users choose the best product. The application allows users to compare product listings from various sites and make informed purchasing decisions.',
    },
    {
      id: 2,
      title: 'Ekchhat',
      message:
        'Ekchhat is a social project that emerged during the COVID-19 pandemic to help people in India find information about essential supplies such as oxygen and medication. With 1.4k active users during peak times, the project has made a significant impact by providing critical information to those in need. Through its platform, Ekchhat has become a reliable resource for individuals searching for vital resources during a challenging time.',
    },
    {
      id: 3,
      title: 'SBA Sports',
      message:
        "SBA Sports is a website developed by a learner that focuses on a sports organization primarily involved in badminton. The website serves as a hub for players, coaches, and fans to get the latest news, tournament schedules, and results. It provides an easy-to-use interface that allows visitors to navigate and access information about the organization's events, teams, and services. ",
    },
    {
      id: 4,
      title: 'Maxin',
      message:
        "Oh, how delightful! My personal masterpiece is akin to the famous Jarvis from Marvel Comics, but it's more than just a run-of-the-mill assistant. I developed this project to fulfill my lifelong programming ambition, and I'm continually enhancing it with fresh insights. Rest assured, I shall keep you abreast of any future developments.",
    },
    {
      id: 5,
      title: 'MCU Phase',
      message:
        'This was my a fan hounerd project I created. Basically its a listing of all the marvel timeline movie in an order with some css obviously but yeah Now I am thinkung to imrove it a bit. So I am on it too.',
    },
    {
      id: 6,
      title: 'LeeWet',
      message:
        "LeeWet is a weather forecast progressive web app built using React.js with a minimalist UI. It provides users with accurate weather information, including current conditions, hourly and daily forecasts, and severe weather alerts. LeeWet's clean and straightforward design allows users to quickly access weather data for any location, with intuitive navigation and clear, easy-to-read graphics. As a progressive web app, LeeWet can be installed on a user's device and accessed offline, providing a seamless user experience. With its reliable weather forecasts and intuitive interface, LeeWet has become a go-to resource for people looking to stay informed about the weather.",
    },
    {
      id: 7,
      title: 'AdevuData',
      message:
        "AdevuData is a data visualization tool that clones a component of Tableau, a popular data analytics and visualization software. AdevuData allows users to create pictorial graph data and perform basic analysis on it. Its intuitive interface makes it easy for users to upload data, visualize it in a variety of formats, and extract insights quickly. AdevuData's standout feature is its ability to display data in a visually appealing way, making it easy to spot trends and patterns. While it is a clone of a component of Tableau, AdevuData offers unique features and functionality that make it a valuable tool for data analysts, researchers, and businesses.",
    },
    {
      id: 8,
      title: 'BMW',
      message:
        "BM Water is a website developed to manage the customers of a water supply firm. The website provides a user-friendly interface for customers to sign up, manage their accounts, and access information about their water supply service. It offers features such as automated billing, account management, and customer support, making it easy for users to access the information they need about their water supply service. With BMWater, customers can conveniently manage their accounts and get information about their water supply service from anywhere, at any time. Overall, BMWater has streamlined the water supply firm's customer management process, providing a convenient and efficient solution for both the company and its customers.",
    },
    {
      id: 9,
      title: 'Chat Room (NOT GPT 😀)',
      message:
        'Chat Room is a website that allows people to join chat rooms and communicate with others anonymously in real-time. The website provides a user-friendly interface for users to enter chat rooms and start chatting without revealing their identity. Chat Room ensures that no data is stored after a session closes, making it a secure and private platform for users to communicate. Users can engage in discussions on a variety of topics, connect with like-minded individuals, and make new friends. With Chat Room, users can enjoy the experience of real-time chat while maintaining their privacy and anonymity.',
    },
    {
      id: 10,
      title: 'Basic Banking System',
      message:
        'Basic Banking System is an admin-side banking system that enables the admin to transfer money from one account to another. The system provides a user-friendly interface for the admin to manage accounts, view transaction histories, and perform money transfers. With this system, the admin can efficiently manage customer accounts and easily transfer funds between accounts, making it a convenient solution for banks and financial institutions. Basic Banking System simplifies the process of transferring money, reduces paperwork, and ensures that transactions are completed quickly and efficiently. Overall, this system provides a streamlined solution for banks and financial institutions to manage customer accounts and perform transactions seamlessly.        ',
    },
    {
      id: 11,
      title: 'Social Media Scrapper',
      message:
        'Social Media Scraper is a web scraper that allows users to extract general data from different social media handles and create user profiles. The scraper provides a user-friendly interface for users to input social media handles and extract data, including usernames, profile pictures, follower counts, and other relevant information. With Social Media Scraper, users can easily collect data from various social media platforms and use it for market research, advertising, and other purposes. The tool can help users to gain insights into their target audience, identify trends, and analyze social media behavior. Overall, Social Media Scraper is a valuable tool for businesses and individuals who want to extract data from social media platforms and use it to create user profiles.',
    },
  ]

  useEffect(() => {
    handleBackToTop()
  }, [])

  const projectsList = req => {
    let frg = []

    for (let i in req) {
      frg.push(notices[req[i]])
    }

    // If no related project forund
    if (req[0] === '') {
      frg = []
      frg.push({ id: 1, title: 'Comming Soon', message: 'More project and hobbies experience comming soon' })
    }
    setFrgment(frg)

    let card = document.getElementById('showcase')
    let card2 = document.getElementById('showcase1')

    card.style.opacity = 0
    card2.style.opacity = 0

    function fadeInCase() {
      var opacity = parseFloat(card.style.opacity)
      var opacity2 = parseFloat(card2.style.opacity)

      card.style.display = 'block'
      card2.style.display = 'block'

      if (opacity < 0.7) {
        card.style.opacity = opacity + 0.1
        card2.style.opacity = opacity2 + 0.2
        console.log(opacity)
        setTimeout(fadeInCase, 40)
      }
    }

    fadeInCase()
    setTitle('Related Projects - Jatin Saini')
  }

  const CloseProjectList = () => {
    let card = document.getElementById('showcase')
    let card2 = document.getElementById('showcase1')

    card2.scrollTop = 0

    function fadeInCase() {
      var opacity = parseFloat(card.style.opacity)
      var opacity2 = parseFloat(card2.style.opacity)

      if (opacity > 0) {
        card.style.opacity = opacity - 0.1
        card2.style.opacity = opacity2 - 0.2
        console.log(opacity)
        if (opacity === 0.1) {
          card.style.display = 'none'
          card2.style.display = 'none'
        }
        setTimeout(fadeInCase, 40)
      }
    }

    fadeInCase()
    setTitle('Skills - Jatin Saini')
    console.log('closing')
  }

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="canonical" href="https://jatinbagri.com/skills" />
        <meta
          name="description"
          content="Experienced full-stack engineer proficient in JS, Node, React, Vue, Python, Flask, Django, MongoDB. Passionate about efficient solutions, web development, APIs, Photoshop, singing. Explore my skills for high-quality software expertise."></meta>
      </Helmet>
      {/* Technologies */}
      <FadeIn vars={{ duration: 2 }}>
        {/* showcase card */}
        <div
          id="showcase"
          className="w-screen z-10 hidden bg-gray-800 fixed"
          style={{ height: '100vh', top: 0, left: 0 }}></div>
        <div
          id="showcase1"
          className={`z-20 overscroll-none  opacity-100 hidden select-none overflow-y-scroll fixed border-2 border-gray-400 shadow-2xl rounded-md example left-auto w-11/12 md:w-1/2 ${
            isDarkMode ? 'bg-gray-800' : 'bg-gray-100'
          }`}
          style={{ height: '60vh', scrollbarWidth: 'none' }}>
          <div
            className={`w-1/4 flex flex-wrap p-4 text-center w-full sticky top-0 shadow-lg z-10 ${
              isDarkMode ? 'bg-gray-600 text-white' : 'bg-gray-200'
            }`}>
            <button
              onClick={CloseProjectList}
              className={`text-md text-white relative left-0 md:left-2 shadow-md p-1 px-2 rounded-md ${
                isDarkMode ? 'border-green-600 border' : 'bg-green-600'
              }`}>
              <strong>Close</strong>
            </button>
            <p className="mx-auto text-2xl border-b-2 border-green-500 font-bold">Related Project</p>
          </div>

          <div className={`sm:p-6 snap-y snap-mandatory ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 '}`}>
            <ul className="divide-y divide-gray-400 hover:divide-y-none scroll-smooth snap-y snap-mandatory">
              {frgment.map(notice => (
                <li
                  key={notice.id}
                  className={`py-4 m-4 px-3 snap-center snap-always h-auto rounded-md hover:scale-105 overflow-x-hidden transition duration-300 ease-in-out shadow-md hover:shadow-lg ${
                    isDarkMode ? 'bg-gray-700' : 'bg-white'
                  }`}>
                  <div className="flex flex-wrap">
                    <p
                      className="font-semibold relative left-0 border-b-2 border-yellow-400"
                      style={{ color: 'black !important' }}>
                      {notice.title}
                    </p>
                  </div>
                  <div className="flex flex-wrap mt-2">
                    <p className="relative left-0 text-justify">{notice.message}</p>
                  </div>
                </li>
              ))}
            </ul>
            <button
              onClick={CloseProjectList}
              className={`text-md mt-2 mb-2 mr-4 md:-mr-10 shadow-md relative  px-3 py-2 rounded-md ${
                isDarkMode ? 'border-green-600 border' : 'bg-green-600'
              }`}
              style={{ right: '-40%' }}>
              <strong className="text-md text-white">Close</strong>
            </button>
          </div>
        </div>

        <div className="flex flex-col mx-auto items-center py-36">
          <div className="border-t-4 border-green-500 py-4">
            <h1
              className={`text-4xl font-bold text-center transition ease-in-out duration-500 ${
                isDarkMode ? 'text-white' : 'text-black'
              }`}>
              Techologies
            </h1>
          </div>

          <div className="grid gap-7 grid-cols-2 md:grid-cols-4 lg:grid-cols-5 lg:gap-8 mt-16 mx-auto mt-8 transition ease-in-out duration-500 ">
            <Pop vars={{ delay: 1 }}>
              <SkillCard
                title="Python"
                icon={faPython}
                color="#3776AB"
                projectsList={projectsList}
                projectarray={[0, 1, 3, 7, 8, 10]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.7 }}>
              <SkillCard
                title="React"
                icon={faReact}
                color="#61DAFB"
                projectsList={projectsList}
                projectarray={[4, 5, 6, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.6 }}>
              <SkillCard
                title="Flask /Django"
                icon={faLeafSolid}
                color="#333333"
                projectsList={projectsList}
                projectarray={[0, 1, 7]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 1.2 }}>
              <SkillCard
                title="Node.js"
                icon={faNodeJs}
                color="#339933"
                projectsList={projectsList}
                projectarray={[2, 4, 5, 6, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.7 }}>
              <SkillCard
                title="JavaScript"
                icon={faJs}
                color="#F0DB4F"
                projectsList={projectsList}
                projectarray={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.6 }}>
              <SkillCard
                title="Server"
                icon={faServer}
                color="#333333"
                projectsList={projectsList}
                projectarray={[0, 1, 2, 4, 5, 6, 7, 8, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.2 }}>
              <SkillCard
                title="Databases"
                icon={faDatabase}
                color="#4479A1"
                projectsList={projectsList}
                projectarray={[0, 1, 7, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.9 }}>
              <SkillCard
                title="GitHub"
                icon={faGithub}
                color="#333333"
                projectsList={projectsList}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.4 }}>
              <SkillCard
                title="HTML"
                icon={faHtml5}
                color="#E44D26"
                projectsList={projectsList}
                projectarray={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 1.2 }}>
              <SkillCard
                title="CSS"
                icon={faCss3Alt}
                color="#1572B6"
                projectsList={projectsList}
                projectarray={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]}
                isDarkMode={isDarkMode}
              />
            </Pop>
          </div>
          {/* <img className="w-40 mt-8" src={codingSvg} alt="Coding" /> */}
        </div>
      </FadeIn>

      {/* Intrest */}
      <FadeIn vars={{ duration: 2 }}>
        <div className="flex flex-col mx-auto items-center">
          {/* <h2 className="text-xl mt-5 bg-gradient-to-r from-red-500 to-yellow-500">Programming Languages</h2> */}
          <div className="border-t-4 border-green-500 py-4">
            <h1
              className={`text-4xl font-bold text-center transition ease-in-out duration-500 ${
                isDarkMode ? 'text-white' : 'text-black'
              }`}>
              Interests
            </h1>
          </div>

          <div className="grid gap-7 grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:gap-16 mt-16 mx-auto mt-8">
            <Pop vars={{ delay: 0.4 }}>
              <SkillCard
                title="Web Dev."
                icon={faCode}
                color="#E44D26"
                projectsList={projectsList}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 1.5 }}>
              <SkillCard
                title="Web Crawling"
                icon={faSpider}
                color="#339933"
                projectsList={projectsList}
                projectarray={[0, 3, 10]}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.7 }}>
              <SkillCard
                title="Photoshop"
                icon={faCamera}
                color="#1572B6"
                projectsList={projectsList}
                projectarray={['']}
                isDarkMode={isDarkMode}
              />
            </Pop>
            <Pop vars={{ delay: 0.8 }}>
              <SkillCard
                title="Singing"
                icon={faMusic}
                color="#A020F0"
                projectsList={projectsList}
                projectarray={['']}
                isDarkMode={isDarkMode}
              />
            </Pop>
          </div>
        </div>
      </FadeIn>
    </div>
  )
}
export default Skills
