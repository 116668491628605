import React, { useState, useEffect } from 'react'
import Logo from '../images/Logo.jpg'
import { NavLink, Link, useLocation } from 'react-router-dom'
import Light from '../assets/light.svg'
import Night from '../assets/night.svg'
import Home from '../assets/nav-icons/home.png'
import Story from '../assets/nav-icons/story.png'
import Skills from '../assets/nav-icons/skills.png'

export default function Navbar({ isDarkMode, setIsDarkMode, themeSwitch }) {
  const [isNav, setNav] = useState('intro')
  const location = useLocation()

  const [colorChange, setColorchange] = useState(false)
  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setColorchange(true)
    } else {
      setColorchange(false)
    }
  }
  window.addEventListener('scroll', changeNavbarColor)
  // You can use conditional logic to perform actions based on the current path
  useEffect(() => {
    if (location.pathname === '/intro') {
      // Do something for the home page
      setNav('intro')
    } else if (location.pathname === '/story') {
      // Do something for the about page
      setNav('story')
    } else if (location.pathname === '/skills') {
      // Do something for the contact page
      setNav('skills')
    }
  }, [location.pathname])

  const handleStory = () => {
    setTimeout(setNav('story'), 500)
  }

  const handleIntro = () => {
    setTimeout(setNav('intro'), 500)
  }

  const handleSkills = () => {
    setTimeout(setNav('skills'), 500)
  }

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode)
    themeSwitch()
  }
  return (
    <header
      className={`pr-5 pb-3 fixed top-0 body-font Abel z-10 w-full transition ease-in-out duration-300 ${
        colorChange ? (isDarkMode ? 'bg-slate-900/75 backdrop-blur' : 'bg-blue-100/75 backdrop-blur') : 'bg-transparent'
      } ${isDarkMode ? 'text-white ' : 'text-gray-800'}`}>
      <div className="container pt-5 mx-auto flex p-3 justify-between items-center lg:pl-26">
        <Link
          to="/"
          onClick={handleIntro}
          className="title-font mr-5 font-medium text-white flex whitespace-no-wrap items-center hidden lg:block">
          <span
            className={`text-lg lg:text-2xl font-semibold border-b Abel transition ease-in-out whitespace-no-wrap ${
              isDarkMode ? 'text-white  border-green-500' : 'text-gray-900  border-black'
            }`}>
            Jatin Saini
          </span>
        </Link>
        <nav className="flex items-center pl-3">
          <NavLink
            to="/story"
            onClick={handleStory}
            className={({ isActive }) =>
              isActive
                ? 'mr-3 text-green-600 dark:text-green-500 text-orange-500 w-20 border border-black dark:border-gray-100 px-3 overflow-hidden py-2 flex rounded-full transition-all transform duration-700 text-sm'
                : 'mr-3 hover:text-orange-500 dark:hover:text-green-500 w-16 transition ease-in-out rounded-full px-4 border border-gray-500 py-2 overflow-hidden flex hover:border-black dark:hover:border-gray-100 hover:-translate-y-1 transition-all transition transform duration-700 text-sm'
            }>
            {isNav == 'story' ? <img src={Story} className="h-5 w-5 mr-1 -mt-px" alt="Story" /> : <></>}
            Story
          </NavLink>
          <NavLink
            to="/"
            onClick={handleIntro}
            className={({ isActive }) =>
              isActive
                ? 'mr-3 text-green-600 dark:text-green-500 text-orange-500 w-20 border border-black dark:border-gray-100 px-3 py-2 rounded-full overflow-hidden flex transition-all transition transform duration-700 text-sm'
                : 'mr-3 hover:text-orange-500 dark:hover:text-green-500 w-16 transition ease-in-out rounded-full px-4 border border-gray-500 py-2 overflow-hidden hover:border-black dark:hover:border-gray-100 flex hover:-translate-y-1 transition-all transition transform duration-700 text-sm'
            }>
            {isNav == 'intro' ? <img src={Home} className={`h-5 w-5 mr-1 -mt-px`} alt="Home" /> : <></>}
            Intro
          </NavLink>
          <NavLink
            to="/skills"
            onClick={handleSkills}
            className={({ isActive }) =>
              isActive
                ? 'mr-4 text-green-600 dark:text-green-500 text-orange-500 w-20 border border-black dark:border-gray-100 px-3 flex py-2 overflow-hidden rounded-full flex  transition-all transition transform duration-700 text-sm'
                : 'mr-4 hover:text-orange-500 dark:hover:text-green-500 w-16 transition ease-in-out rounded-full px-4 flex border border-gray-500 overflow-hidden py-2 hover:border-black dark:hover:border-gray-100 flex hover:-translate-y-1 transition-all transition transform duration-700 text-sm'
            }>
            {isNav == 'skills' ? <img src={Skills} className="h-5 w-5 mr-1 -mt-px" alt="Home" /> : <></>}
            Skills
          </NavLink>

          <div className="flex items-center select-none absolute right-10 md:static">
            <p
              onClick={handleToggle}
              style={{ cursor: 'pointer' }}
              className={`w-12 h-6 rounded-full focus:outline-none transition-colors shadow-inner duration-300 ease-in-out ${
                isDarkMode ? 'bg-gray-600 shadow-gray-900' : 'bg-gray-100 shadow-gray-500'
              }`}>
              <div
                className={`w-8 h-8 -mt-1 p-1 focus:outline-none rounded-full shadow-inner shadow-yellow-500 dark:shadow-indigo-600 transform ${
                  isDarkMode ? 'translate-x-6 ease-in-out bg-gray-900 ' : 'bg-orange-100'
                } transition-transform duration-300 ease-in-out`}>
                <div className="">
                  {isDarkMode ? <img src={Night} className="w-6 h-6" /> : <img src={Light} className="w-6 h-6" />}
                </div>
              </div>
            </p>
          </div>
        </nav>
      </div>
    </header>
  )
}
