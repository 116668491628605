// LuminousEffect.js

import React, { useState, useEffect } from 'react'
import './LuminousEffect.css' // Import CSS file

const LuminousEffect = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 })

  // Update mouse position
  const updatePosition = e => {
    setPosition({ x: e.clientX, y: e.clientY })
  }

  useEffect(() => {
    document.addEventListener('mousemove', updatePosition)
    return () => {
      document.removeEventListener('mousemove', updatePosition)
    }
  }, [])

  return (
    <div className="fixed opacity-40 hidden lg:block">
      <div className="absolute luminous" style={{ left: position.x, top: position.y }}></div>
    </div>
  )
}

export default LuminousEffect
