import React, { useState, useEffect } from 'react'

// logos
import School from '../images/school.jpeg'
import College from '../images/college.jpeg'
import TLC from '../images/tlc.jpeg'
import Swagup from '../images/swagup.jpg'
import Visionify from '../images/visionify.jpeg'
import PeacockSolar from '../images/peacocksolar.jpeg'
import SparkFoundation from '../images/sparkfoundation.jpeg'
import Hackathon from '../images/hackathon01.jpg'
import Sensecart from '../images/SenseCart.PNG'
import Ekchhat from '../images/ekchhat.png'

import './storyV2.css'

const PortfolioPage = ({ isDarkMode, handleBackToTop }) => {
  const [selectedOption, setSelectedOption] = useState('experience')
  const [colorChange, setColorchange] = useState(false)
  const changeNavbarColor = () => {
    if (window.scrollY >= 30) {
      setColorchange(true)
    } else {
      setColorchange(false)
    }
  }
  window.addEventListener('scroll', changeNavbarColor)
  const handleOptionClick = option => {
    setSelectedOption(option)
  }

  useEffect(() => {
    handleBackToTop()
  }, [selectedOption])

  const renderContent = () => {
    const education = [
      {
        logo: College,
        name: 'MITRC',
        start: '2018',
        end: '2022',
        heading: 'Electrical Engineering',
        desc: [
          `I earned my degree in Electrical Engineering from Bikaner Technical University. Alongside my studies, I delved deeper into hardware-level programming, mastering microprocessors like Arduino and Raspberry Pi. Furthermore, I initiated my journey into web development, focusing on core Python and Flask frameworks.`,
          `I've also had the honor of being a two-time winner at inter-college project exhibitions, showcasing my innovative projects. Additionally, I proudly represented my college at the prestigious Smart India Hackathon, further demonstrating my passion for problem-solving and innovation.`,
        ],
      },
      {
        logo: School,
        name: 'National Academy',
        start: '2017',
        end: '2018',
        heading: 'Higher Education ( P,C,M )',
        desc: [
          'I completed majors in Maths and Science, and I also delved into databases, mastering MySQL, Java, Oracle, and gaining a grasp of HTML/CSS basics.',
          `In addition to my academic achievements, I've also represented my school multiple times at the district level in football and basketball games.`,
        ],
      },
    ]
    const projects = [
      {
        logo: Hackathon,
        name: 'Smart India Hackathon',
        start: 'Aug / 2022 ',
        heading: 'Tech Lead',
        desc: [
          `We led the development of a project aimed at assisting disabled individuals, fostering a deeper understanding of team dynamics and collaboration.`,
          `As a finalist in the 2022 Hackathon, I not only showcased my technical prowess but also learned invaluable lessons in team building. Participating in a national-level competition was a thrilling experience, pushing me to excel and collaborate effectively in a high-stakes environment.`,
        ],
        techStack: ['JavaScript', 'NextJs', 'Firebase 🔥', 'SMTP', 'MongoDb'],
      },
      {
        logo: Ekchhat,
        name: 'Ekkchat (Covid 19 Project)',
        start: 'May / 2020',
        heading: 'Social Project',
        desc: [
          `It's my initiative in response to covid 19 and ongoing lockdown at that time. I was ambivalenced with the situation. Scarcity of medicine was dealing with life.`,
          `During the peak of COVID-19, my app reached 2.1k active users, I feel blessed to make a difference in the lives of a few.`,
          'Developed the full fledged app and deployed in just 2 days working round the clock.',
        ],
        techStack: ['JavaScript', 'Google Analytics', 'Python', 'Tailwind CSS'],
      },
      {
        logo: Sensecart,
        name: 'SenseCart',
        start: '2018',
        heading: '1st Full-Stack Project',
        desc: [
          `In our second year of college, we embarked on creating a web application for comparing online products, though it turned out to be a failed attempt due to an inefficient algorithm. 😅`,
          `Later, I improved its algorithm, drastically reducing query time from around 15 seconds initially to just 3 seconds, achieving nearly a fivefold reduction in latency.`,
        ],
        techStack: ['JavaScript', 'Google Analytics', 'Python', 'Tailwind CSS'],
      },
    ]
    const experience = [
      {
        logo: Swagup,
        name: 'SwagUp',
        start: '2023',
        end: 'Present',
        heading: 'Full Stack Enginner',
        desc: [
          `Led Back-end and Front-end development at SwagUp, using Python, Django, and ReactJs to build a platform for merchandise
          distribution in cooperation with third-party vendors.`,
          `Collaborated across teams, applying Agile methodologies and Git for efficient project management and code integrity.`,
        ],
        techStack: ['JavaScript', 'React', 'NodeJs', 'Django', 'Python'],
      },
      {
        logo: Visionify,
        name: 'Visionify',
        start: '2021',
        end: '2023',
        heading: 'Full Stack Enginner',
        desc: [
          `Worked as a full-stack engineer, where I took on the responsibility of developing scalable systems. Utilizing Python with Django or Flask frameworks for the backend and Vue.js or Node.js for backend services`,
          `I ensured robust and efficient solutions for various projects across the board for our clients.`,
        ],
        techStack: ['NextJs', 'VueJs', 'Redis', 'Flask', 'MySql', 'AWS'],
      },
      {
        logo: SparkFoundation,
        name: 'The Sparks Foundation',
        start: 'Aug / 2021',
        end: 'Sep / 2021',
        heading: 'Web Developer Internship',
        desc: [
          `Embarking on a project-based internship journey, I navigated solo through challenges, crafting innovative solutions within strict timeframes. With each project, I etched my skills and achievements onto my professional canvas, showcasing my prowess in a well-recognized organization known for its dynamic internship experiences.`,
        ],
        techStack: ['HTML', 'CSS', 'JavaScript', 'Flask', 'MongoDb'],
      },
      {
        logo: PeacockSolar,
        name: 'Peacock Solar',
        start: 'July / 2021',
        end: 'Sep / 2021',
        heading: 'Web Developer Internship',
        desc: [
          `As an SDE Intern at Peacock Solar, a leading solar energy company, I contributed to projects aimed at providing sustainable energy solutions to households and industries. With a focus on innovation and efficiency, I played a pivotal role in developing and enhancing two major projects within the organization.`,
        ],
        // techStack: ['HTML', 'CSS', 'JavaScript', 'Flask', 'MongoDb'],
      },
      {
        logo: TLC,
        name: 'Thanisha Lifestyle Creation',
        start: 'Feb / 2021',
        end: 'April / 2021',
        heading: 'Web Designer Internship',
        desc: [
          `In February 2021, I embarked on my first internship journey, diving into a full-stack React project. Immersed in the world of antique commerce, I collaborated with a team dedicated to bringing their unique products online. `,
        ],
        techStack: ['React', 'HTML/CSS', 'Figma', 'Photoshop', 'MongoDb'],
      },
    ]
    switch (selectedOption) {
      case 'education':
        return (
          <div className="mt-20 pl-5 lg:mt-0 lg:pl-0">
            {education.map(item => (
              <div className="max-w-full rounded overflow-hidden">
                <div className="w-fit mb-3">
                  <p className="text-gray-400 focus-visible:text-teal-300 font-medium text-xl whitespace-nowrap">
                    {item.start} - {item.end}
                  </p>
                </div>
                <div>
                  <img className="w-20 shadow-md rounded-md h-20" src={item.logo} alt="Sunset in the mountains" />
                  <div className="pr-6 py-4">
                    <div className={`font-bold text-lg mb-2 text-left ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {item.name}
                    </div>
                    <div className={`font-bold text-lg mb-2 text-left ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {item.heading}
                    </div>
                    {item.desc && (
                      <ul>
                        {item.desc.map(pointer => (
                          <li
                            className={`text-left text-justifty mb-5 ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>
                            {pointer}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      case 'projects':
        return (
          <div className="mt-20 pl-5 lg:mt-0 lg:pl-0">
            {projects.map(item => (
              <div className="max-w-full rounded overflow-hidden mt-5">
                <div className="w-fit mb-3">
                  <p className="text-gray-400 focus-visible:text-teal-300 font-medium text-xl whitespace-nowrap">
                    {item.start}
                  </p>
                </div>
                <div>
                  <img className="w-20 shadow-md rounded-md h-20" src={item.logo} alt="Sunset in the mountains" />
                  <div className="pr-6 py-4">
                    <div className={`font-bold text-lg mb-2 text-left ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {item.name}
                    </div>
                    <div className={`font-bold text-lg mb-2 text-left ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {item.heading}
                    </div>
                    {item.desc && (
                      <ul>
                        {item.desc.map(pointer => (
                          <li
                            className={`text-left text-justifty mb-5 ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>
                            {pointer}
                          </li>
                        ))}
                      </ul>
                    )}
                    {item.techStack && (
                      <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                        {item.techStack.map(tech => (
                          <li className="mr-1.5 mt-2">
                            <div
                              className={`flex items-center rounded-full px-3 py-1 text-xs font-medium leading-5 ${
                                isDarkMode ? 'bg-teal-400/10 text-teal-300' : 'bg-orange-400/10 text-orange-600'
                              }`}>
                              {tech}
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      case 'experience':
        return (
          <div className="mt-20 pl-5 lg:mt-0 lg:pl-0">
            {experience.map(item => (
              <div className="max-w-full rounded overflow-hidden mt-5">
                <div className="w-fit mb-3">
                  <p className="text-gray-400 focus-visible:text-teal-300 font-medium text-xl whitespace-nowrap">
                    {item.start} - {item.end}
                  </p>
                </div>
                <div>
                  <img className="w-20 shadow-md rounded-md h-20" src={item.logo} alt="Sunset in the mountains" />
                  <div className="pr-6 py-4">
                    <div className={`font-bold text-lg mb-2 text-left ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {item.name}
                    </div>
                    <div className={`font-bold text-lg mb-2 text-left ${isDarkMode ? 'text-white' : 'text-black'}`}>
                      {item.heading}
                    </div>
                    {item.desc && (
                      <ul>
                        {item.desc.map(pointer => (
                          <li
                            className={`text-left text-justifty mb-5 ${
                              isDarkMode ? 'text-gray-400' : 'text-gray-500'
                            }`}>
                            {pointer}
                          </li>
                        ))}
                      </ul>
                    )}
                    {item.techStack && (
                      <ul className="mt-2 flex flex-wrap" aria-label="Technologies used">
                        {item.techStack.map(tech => (
                          <li className="mr-1.5 mt-2">
                            <div
                              className={`flex items-center rounded-full px-3 py-1 text-xs font-medium leading-5 ${
                                isDarkMode ? 'bg-teal-400/10 text-teal-300' : 'bg-orange-400/10 text-orange-600'
                              }`}>
                              {tech}
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className="bg-transparent min-h-screen flex hidden lg:block mt-10">
        <div className="container mx-auto py-12 flex justify-between">
          <div className="w-10 pt-52 fixed ">
            <div className="flex flex-col">
              <button
                onClick={() => handleOptionClick('experience')}
                className={`py-2 px-4 focus:outline-none mx-auto text-lg ${
                  selectedOption === 'experience'
                    ? 'mb-4 rounded-md dark:text-white text-black font-bold'
                    : 'text-gray-600 hover:text-black dark:hover:text-white transition ease-in-out duration-300 transition-opacity ease-in duration-700 opacity-50 hover:opacity-100'
                }`}>
                <p
                  className={`${
                    selectedOption == 'experience'
                      ? 'transition ease-in-out duration-300 w-fit border-orange-500 dark:border-green-500 border-l-4 pl-2 py-2'
                      : ''
                  } `}>
                  Experience
                </p>
              </button>
              <button
                onClick={() => handleOptionClick('projects')}
                className={`py-2 px-4 focus:outline-none mx-auto text-lg ${
                  selectedOption === 'projects'
                    ? 'mb-4 rounded-md dark:text-white text-black font-bold'
                    : 'text-gray-600 hover:text-black dark:hover:text-white transition ease-in-out duration-300 transition-opacity ease-in duration-700 opacity-50 hover:opacity-100'
                }`}>
                <p
                  className={`${
                    selectedOption == 'projects'
                      ? 'transition ease-in-out duration-300 w-fit border-orange-500 dark:border-green-500 border-l-4 pl-2 py-2'
                      : ''
                  } `}>
                  Projects
                </p>
              </button>
              <button
                onClick={() => handleOptionClick('education')}
                className={`py-2 px-4 focus:outline-none mx-auto text-lg ${
                  selectedOption === 'education'
                    ? 'mb-4 rounded-md dark:text-white text-black font-bold'
                    : 'text-gray-600 hover:text-black dark:hover:text-white transition ease-in-out duration-300 transition-opacity ease-in duration-700 opacity-50 hover:opacity-100'
                }`}>
                <p
                  className={`${
                    selectedOption == 'education'
                      ? 'transition ease-in-out duration-300 w-fit border-orange-500 dark:border-green-500 border-l-4 pl-2 py-2'
                      : ''
                  } `}>
                  Education
                </p>
              </button>
            </div>
          </div>
          <div className="flex-grow ml-40">
            <div className="max-w-xl h-fit mx-auto bg-transparent rounded-lg overflow-hidden">{renderContent()}</div>
          </div>
        </div>
      </div>
      <div className="bg-transparent min-h-screen flex block lg:hidden">
        <div className="container mx-auto py-12 lg-flex lg-justify-between">
          <div className="lg:w-1/4 fixed lg:pt-0 fixed lg:top-auto lg:relative w-full lg:w-auto">
            <div
              className={`pt-5 overflow-hidden w-full flex flex-wrap fixed top-20 z-10 justify-center items-center
               ${colorChange ? 'dark:bg-slate-900/75 backdrop-blur bg-blue-100/75' : 'bg-transparent'} `}>
              <button
                onClick={() => handleOptionClick('experience')}
                className={`py-2 px-4 focus:outline-none ml-2 mx-auto ${
                  selectedOption === 'experience'
                    ? 'mb-4 rounded-md text-black dark:text-white'
                    : 'text-gray-800 dark:text-gray-400'
                }`}>
                <p
                  className={`transition ease-in-out duration-300 ${
                    selectedOption == 'experience'
                      ? 'transition ease-in-out duration-300 w-fit dark:border-green-400 border-orange-500 px-2 pb-1 border-b-2'
                      : ''
                  } `}>
                  Experience
                </p>
              </button>
              <button
                onClick={() => handleOptionClick('projects')}
                className={`py-2 px-4 mb-2 focus:outline-none mx-auto ${
                  selectedOption === 'projects'
                    ? 'mb-4 rounded-md text-black dark:text-white'
                    : 'text-gray-800 dark:text-gray-400'
                }`}>
                <p
                  className={`${
                    selectedOption == 'projects'
                      ? 'transition ease-in-out duration-300 w-fit dark:border-green-400 border-orange-500 px-2 pb-1 border-b-2'
                      : ''
                  } `}>
                  Projects
                </p>
              </button>
              <button
                onClick={() => handleOptionClick('education')}
                className={`py-2 px-4 mb-2 focus:outline-none mx-auto ${
                  selectedOption === 'education'
                    ? 'mb-4 rounded-md text-black dark:text-white'
                    : 'text-gray-800 dark:text-gray-400'
                }`}>
                <p
                  className={`${
                    selectedOption == 'education'
                      ? 'transition ease-in-out duration-300 w-fit dark:border-green-400 border-orange-500 px-2 pb-1 border-b-2'
                      : ''
                  } `}>
                  Education
                </p>
              </button>
            </div>
          </div>
          <div className="flex-grow mt-10">
            <div className="max-w-4xl mx-auto bg-transparent w-fit rounded-lg overflow-hidden">{renderContent()}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioPage
